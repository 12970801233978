@media(max-width: 900px){
.create-status-container {
  width: 100%;
  height: 100vh!important;
  background-color: #f2f2f7;
}
.create-status-header {
  background-color: #fff;
  display: flex;
  border-bottom: 1px solid #e5e7eb;
  box-shadow: 0px 0px 10px #e5e7eb;
}
.create-status-header h3 {
  font-size: 1rem!important;
  font-weight: 600!important;
  color: #333!important;
  text-transform: capitalize!important;
}
.create-status-header .close-btn {
  color: #000;
}
.status-name-input {
  font-size: 14px;
  color: #303030;
  outline: none !important;
  border: none !important;
}
.status-color-row {
  margin-top: 1rem;
  gap: 0.5rem;
  grid-template-columns: repeat(5, minmax(0, 1fr));
  display: grid;
}
.status-color-card {
  justify-content: center;
  align-items: center;
  display: flex;
  height: 3.5rem;
  cursor: pointer;
  border-radius: 0px;
  padding: 0 !important;
  color: rgb(255, 255, 255);
  flex-direction: row;
  border: none;
  box-shadow: 0px 0px 10px #ccc;
}
.status-color-card svg {
  height: 30px;
  width: 30px;
}
}