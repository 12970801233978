@media(min-width: 900px){

.donutchart{
    width: 100%!important;
    height: 260px!important;
    padding: 1rem!important;
}
.donutchart-innertext{
    color: #74788d!important;
    font-size: 22px!important;
    font-weight: 400!important;
}
.donutchart-legend{
    color: #74788d!important;
    font-size: 20px!important;
    font-weight: 400!important;
}

/*  */ 
.CircularProgressbar{
    width: 3.5rem!important;
} 
.CircularProgressbar-text { 
    fill: rgb(123, 128, 154)!important;
}

}