@media(max-width: 900px){
.top-header {
  background-color: #fff;
  text-align: center;
  margin: 0px;
  padding: 15px 0px!important;
}
.top-header h3 {
  margin: 0;
  font-size: 1rem!important;
  font-weight: 600!important;
}
.inner-top-header h3{
  font-size: 1rem!important;
  font-weight: 600!important;
}
.inner-top-header {
  background-color: #fff;
  display: flex;
  color: #000;
  font-weight: 600;
  text-transform: capitalize;
  font-size: 13px;
}

.inner-top-header .close-btn{
  color: #000;
}
}