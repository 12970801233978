@media(min-width: 900px){

  .source-card {
    color: rgba(0, 0, 0, 0.87)!important;
    transition: box-shadow 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms!important;
    display: flex!important;
    flex-direction: column!important;
    position: relative!important;
    min-width: 0px!important;
    overflow-wrap: break-word!important;
    background-color: rgb(255, 255, 255)!important;
    background-clip: border-box!important;
    border: 0px solid rgba(0, 0, 0, 0.125)!important;
    border-radius: 0.75rem!important;
    box-shadow: rgb(0 0 0 / 10%) 0rem 0.25rem 0.675rem -0.0625rem, rgb(0 0 0 / 6%) 0rem 0.125rem 0.65rem -0.0625rem!important;
    overflow: visible!important;
    cursor: pointer!important; 
    padding-bottom: 0%!important;
  }
  .srcimg{
    width: 4rem!important;
    height: 4rem!important;
    margin-top: -1.8rem!important;
    opacity: 1!important;
    /* background: linear-gradient(195deg, rgb(66, 66, 74), rgb(25, 25, 25)); */
    color: rgb(255, 255, 255)!important;
    border-radius: 0.75rem!important;
    box-shadow: rgb(0 0 0 / 14%) 0rem 0.25rem 1.25rem 0rem, rgb(64 64 64 / 40%) 0rem 0.4375rem 0.625rem -0.3125rem!important;
    border-radius: 100%!important;
  }
  .sravatar{
    width: 4rem!important;
    height: 4rem!important;
    margin-top: -1.8rem!important;
    opacity: 1!important;
    background: linear-gradient(195deg, rgb(66, 66, 74), rgb(25, 25, 25));
    color: rgb(255, 255, 255)!important;
    border-radius: 0.75rem!important;
    box-shadow: rgb(0 0 0 / 14%) 0rem 0.25rem 1.25rem 0rem, rgb(64 64 64 / 40%) 0rem 0.4375rem 0.625rem -0.3125rem!important;
    border-radius: 100%!important;
    font-size: 24px;
    text-align: center;
    padding: 11px;
    outline: none;
    border: none;
  }
  .srcname{
    font-size: 0.875rem!important;
    line-height: 1.5!important;
    letter-spacing: 0.02857em!important;
    opacity: 1!important;
    text-transform: none!important;
    vertical-align: unset!important;
    text-decoration: none!important;
    color: rgb(123, 128, 154)!important;
    font-weight: 400!important; 
  }
  .srccount{
    margin: -20px 0 0 0!important;
    font-size: 1.5rem!important;
    font-weight: 700!important;
    letter-spacing: 0.00735em!important;
    opacity: 1!important;
    text-transform: none!important;
    vertical-align: unset!important;
    text-decoration: none!important;
    color: rgb(52, 71, 103)!important;
    text-align: right!important;
  }
  .srcpercent{
    margin: 0px!important;
    font-size: 0.875rem!important;
    font-weight: 300!important;
    line-height: 1.5!important;
    letter-spacing: 0.02857em!important;
    display: flex!important;
    opacity: 1!important;
    text-transform: none!important;
    vertical-align: unset!important;
    text-decoration: none!important;
    color: rgb(123, 128, 154)!important;
  }
  
  }