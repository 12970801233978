@media(max-width: 900px){
.followup-bar {
  width: 100%;
  overflow: hidden;
  background-color: #fff !important;
  font-size: 0.875rem;
  display: inline-flex;
  text-decoration: none;
  padding: 0%!important;
}
.followup-bar-icon {
  margin: auto 0.75rem;
}
.right-side {
  position: absolute;
  display: flex;
  right: 0rem;
}
/* .right-side h3{
  color: rgb(214, 58, 58)!important; 
} */
.followup-bar-righticon svg{
  margin: auto 7px!important;
  width: 17px!important;
  height: 17px!important;
  /* color: #676767!important; */
  padding: 13px 0!important;
  /* color: rgb(214, 58, 58)!important; */
}
.followup-bar h3 {
  font-weight: 600 !important;
  text-transform: capitalize !important;
  font-size: 14px !important;
}
}