@media(min-width: 900px){

.leftsidenav div {
  background: #252b3b !important;
  background-color: #252b3b !important;
  color: #8590a5!important;
}
.leftsidenav div:hover {
  color: white !important;
}
.leftsidenav .active div {
  color: white !important;
}
.leftsidenav button {
  color: #8590a5 !important;
}
.leftsidenav li,
.leftsidenav span {
  font-size: 13.3px!important;
  font-weight: 500!important;
}
.leftsidenav svg {
  font-size: 1.1rem!important;
  vertical-align: middle!important;
  transition: all 0.4s!important;
  opacity: 0.75!important;
}
/* .css-1tl6s6j { */
  /* box-shadow: 0 2px 4px rgb(0 0 0 / 8%) !important; */
/* } */

header {
  /* width: calc(100% - 230px)!important; */
  background-color: white !important;
  color: #636e75 !important;
  box-shadow: 0 2px 4px rgb(0 0 0 / 8%) !important;
}
header button {
  border-radius: 0% !important;
}
/* .css-to4x1n-MuiBadge-badge { */
  /* background-color: #ff3d60 !important; */
/* } */
.searchbar {
  border: 1px solid #f1f5f7!important;
  height: 38px!important; 
  background-color: #f1f5f7!important;
  box-shadow: none!important;
  border-radius: 10px!important;
  color: #636e75 !important;
}
.userimg {
  height: 36px!important;
  width: 36px!important;
  background-color: #f1f5f7!important;
  padding: 3px!important;
  border-radius: 100%!important;
}
.dtopbar button {
  border-radius: 0% !important;
}
.title {
  font-size: 14px!important;
  padding-left: 5px!important;
  padding-right: 5px!important;
}
.dropdown ul li {
  width: 100%!important;
  clear: both!important;
  font-weight: 400!important;
  text-align: inherit!important;
  background-color: transparent!important;
  border: 0!important;
  font-size: 14px !important;
  font-family: Arial, Helvetica, sans-serif!important;
}
.css-aqqxug-MuiPaper-root-MuiMenu-paper-MuiPaper-root-MuiPopover-paper
  .MuiAvatar-root {
  margin: 0% !important;
}

/* .css-96uuyl{margin-left: 0%!important;} */

.fromdate {
  margin-top: 9px !important;
}
.fromdate input {
  padding: 10px !important;
  width: 95px!important;
}
.user-details span {
  font-size: 0.8rem!important;
  font-weight: 500!important;
  letter-spacing: 0.02857em!important;
  color: rgb(232, 232, 232)!important;
}
.user-details p {
  font-size: 0.775rem!important;
  line-height: 1.5!important;
  letter-spacing: 0.02857em!important;
  color: rgb(123, 128, 154)!important;
  font-weight: 500!important;
}

}