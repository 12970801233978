@media(min-width: 900px){

.recharts-tooltip-wrapper{
    border: none!important; 
    outline: none!important;
    font-size: 13px!important;
    font-weight: 400!important;
}
.custom-tooltip{
    font-size: 13px!important;
    font-weight: 400!important;
    background-color: #f1f5f7eb!important;
    color: rgb(123, 128, 154)!important;
    padding: 5px 10px!important;
    box-shadow: rgb(0 0 0 / 10%) 0rem 0.25rem 0.375rem -0.0625rem, rgb(0 0 0 / 6%) 0rem 0.125rem 0.25rem -0.0625rem!important;
    cursor: pointer!important;
    border: none!important; 
    outline: none!important;
}
.recharts-responsive-container{
    font-size: 13px!important;
    font-weight: 400!important;
} 

}