@media(max-width: 900px){
.status-card {
    width: 100%;
    overflow: hidden;
    background-color: #fff; 
    font-size: 0.875rem;
    display: inline-flex;
    text-decoration: none!important;
    color: #000;
    padding: 0%!important;
}
.status-card .status-color {
    width: 1.25rem;
    height: 1.25rem;
    margin: auto 0.75rem;
}
.new-lead-icon {
    margin: auto 0.75rem;
}
.right-side {
    position: absolute;
    display: flex;
    right: 0rem;
}
.status-icon {
    margin: auto 5px!important;
    width: 17px!important;
    height: 17px!important;
    color: #676767!important;
}
h3{
    font-weight: 600!important;
    color: #333;
    text-transform: capitalize!important;
    font-size: 14px!important;
}
}