@media(max-width: 900px){
    header{
        background-color: #ffffff!important;
        box-shadow: none!important;
        left: 0;
        width: 100%!important;
        top: 0;
        z-index: 1000!important;
        position: fixed!important;
    }
    header input{
        background: #f2f2f7!important;
        border-radius: 6px; 
        color: #333333!important;
        box-shadow: 0 0 2px #f2f2f7!important;
        padding-right: 3.8rem!important;
        padding-left: 2.6rem!important;
    }
}
/* .css-1kcggdq-MuiInputBase-root .MuiInputBase-input{
    padding-left: calc(1em + 22px)!important;
} */