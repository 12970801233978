@media(min-width: 900px){

.modal-header {
  justify-content: space-between!important;
  padding: 1rem!important;
  border-bottom: 2px solid #eff2f7!important;
  border-top-left-radius: calc(0.4rem - 1px)!important;
  border-top-right-radius: calc(0.4rem - 1px)!important;
}
.modal-title {
  font-size: 1rem!important;
  font-weight: 700!important;
  letter-spacing: 0.0075em!important;
  text-transform: capitalize!important;
  vertical-align: unset!important;
  text-decoration: none!important;
  color: rgb(52, 71, 103)!important;
}
.modal-close-btn {
  border: 0!important;
  font-size: 33px!important;
  background: none!important;
  position: absolute!important;
  top: 0!important;
  right: 0!important;
  opacity: 0.5!important;
  cursor: pointer!important;
  z-index: 2!important;
}

}