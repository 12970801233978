@media(max-width: 900px){
.leadicon {
  font-size: 20px !important;
  padding: 6px 10px !important; 
  color: #fff !important;
  font-weight: 500 !important;
  border-radius: 2px!important;
}
.leaddate {
  position: absolute !important;
  right: 1rem !important;
  bottom: 1px !important;
  font-size: 10px !important;
  color: #889097 !important;
  font-weight: 400 !important;
} 
.leadbutton {
  text-align: left!important;
  justify-content: left!important;
  position: absolute !important;
  left: 5.5rem !important;
  bottom: 0.8rem !important; 
  border-radius: 2px!important;
  border: 1px solid #2a5ebf!important;
  background-color: transparent!important;
  height: auto!important;
  width: 80px!important; 
}
.MuiChip-avatar{
  margin-left: -1px!important;
  margin-right: -11px!important;
  width: 20px;
  height: 19px!important;
  font-weight: 500!important;
  color: #2a5ebf!important;
  padding: 0%!important;
  font-size: 12px!important;
  border: none!important;
  border-radius: 0%!important;
  background: transparent!important;
}
.leadbutton span{
  text-transform: capitalize!important;
  font-size: 11px !important;
  font-weight: 400!important;
  text-overflow: ellipsis !important;
  white-space: nowrap !important;
  overflow: hidden !important;
  max-width: 60px !important;
  color: #2a5ebf!important;
  padding-right: 5px!important;
}
.leadbutton1 {
  text-align: left!important;
  text-transform: capitalize!important;
  font-size: 11px !important;
  font-weight: 400!important;
  position: absolute !important;
  left: 10.8rem !important;
  bottom: 0.8rem !important; 
  padding: 5px 5px !important;
  text-overflow: ellipsis !important;
  white-space: nowrap !important;
  overflow: hidden !important; 
  border-radius: 0 !important; 
  line-height: 1!important;
  min-width: auto!important;
  border-radius: 2px!important;
}
.listitem {
  padding-top: 15px !important;
  padding-bottom: 18px !important;
}
.lead-title span , .newlead-title span{
  font-weight: 500 !important; 
  text-transform: capitalize !important;
  font-size: 16px !important;
  text-overflow: ellipsis !important;
  white-space: nowrap !important;
  overflow: hidden !important;
  max-width: 160px !important;
}
.newlead-title span{padding-left: 4.2rem!important;}
.newlead-title:before {
  position: absolute!important;
  top: 0.5rem!important;
  content: "New Lead"!important;
  font-size: 12px;
  background: #f186b8;
  color: white;
  padding: 1px 4px;
  border-radius: 2px;
}
.newlead-title p {
  text-overflow: ellipsis !important;
  white-space: nowrap !important;
  overflow: hidden !important;
  max-width: 225px !important;
}

.lead-title p{
  font-size: 12.8px !important;
  font-weight: 400 !important; 
  color: #889097!important;
  text-overflow: ellipsis !important;
  white-space: nowrap !important;
  overflow: hidden !important;
  max-width: 200px !important;
}
}