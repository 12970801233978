@media(min-width: 900px){

.custom-from-group {
  display: flex!important;
  align-items: center!important;
}
.custom-form-label {
  font-size: 14px !important;
  font-weight: 500 !important;
  color: #636e75 !important;
}

.custom-autocomplete div {
  padding: 0 !important;
}

.custom-autocomplete label {
  z-index: 0!important;
  overflow-y: visible !important ;
  overflow-x: clip !important;
  font-size: 0.9rem !important;
  line-height: 0.5 !important;
}

.custom-multiautocomplete-label {
  line-height: 0.5 !important;
  overflow-y: visible !important;
  overflow-x: clip !important;
  font-size: 0.9rem !important;
}

.custom-multiautocomplete div:first-child {
  padding: 0px !important;
}
.custom-multiautocomplete {
  display: block!important;
  width: 100%!important;
  padding: 0.47rem 0.75rem!important;
  font-size: 0.9rem !important;
  font-weight: 400 !important;
  line-height: 1.5!important;
  color: #505d69 !important;
  background-color: #fff!important;
  border: 1px solid #ced4da!important;
  outline: none!important;
}
.custom-textarea {
  font-family: -apple-system, BlinkMacSystemFont, "Poppins", sans-serif !important;
}

.css-1u9des2-indicatorSeparator,
.css-1xc3v61-indicatorContainer svg {
  display: none !important;
}

.css-1xc3v61-indicatorContainer {
  padding: 0px !important;
}

.custom-input,
.custom-textarea {
  display: block!important;
  width: 100%!important;
  padding: 0.47rem 0.75rem!important;
  font-size: 0.9rem !important;
  font-weight: 400 !important;
  line-height: 1.5!important;
  color: #505d69 !important;
  background-color: #fff;
  border: 1px solid #ced4da!important;
  border-radius: 0.25rem!important;
  outline: none!important;
}

.required-label {
  color: red!important;
}

}