@media(max-width: 900px){
.account-bar,
.logout-bar {
  width: 100%;
  overflow: hidden;
  background-color: #fff !important;
  font-size: 0.875rem;
  display: inline-flex;
  text-decoration: none;
  padding: 0px 15px;
  color: #000;
}

.account-bar h3 {
  text-transform: capitalize !important;
  font-size: 14px !important;
  font-weight: 400;
}

.right-side {
  position: absolute;
  display: flex;
  right: 0rem;
}

.account-bar-righticon {
  margin: auto;
}
.account-bar-righticon svg {
  width: 0.8em !important;
  margin: 5px 5px 0px 5px;
}
}