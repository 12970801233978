@media(min-width: 900px){

  .user-card {
    box-shadow: rgb(0 0 0 / 10%) 0rem 0.25rem 0.375rem -0.0625rem,
      rgb(0 0 0 / 6%) 0rem 0.125rem 0.25rem -0.0625rem!important;
    overflow: visible!important;
    border-radius: 0.75rem!important;
    transition: box-shadow 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms!important;
    cursor: pointer!important;
  }
  .user-name {
    font-size: 1rem!important;
    font-weight: 700!important;
    letter-spacing: 0.0075em!important;
    text-transform: capitalize!important;
    vertical-align: unset!important;
    text-decoration: none!important;
    color: rgb(52, 71, 103)!important;
  }
  .user-img {
    width: 100%!important;
    border-radius: 0.75rem 0.75rem 0 0!important;
  }
  .user-card-body {
    padding: 5px 16px!important;
  }
  .user-subname {
    font-size: 0.875rem!important;
    line-height: 1.5!important;
    letter-spacing: 0.02857em!important;
    text-decoration: none!important;
    color: rgb(123, 128, 154)!important;
    font-weight: 400!important;
  }
  .bxdivider {
    margin: 0!important;
    flex-shrink: 0!important;
    border-top: 0px solid rgba(0, 0, 0, 0.12)!important;
    border-right: 0px solid rgba(0, 0, 0, 0.12)!important;
    border-left: 0px solid rgba(0, 0, 0, 0.12)!important;
    background-color: transparent!important;
    height: 0.0825rem!important;
    border-bottom: none!important;
    opacity: 0.25!important;
    background-image: linear-gradient(
      to right,
      rgba(52, 71, 103, 0),
      rgba(52, 71, 103, 0.4),
      rgba(52, 71, 103, 0)
    )!important;
  }
  
  }